import { useContext, lazy, Suspense } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Spinner } from '@pulse-web-ui/spinner';
import { AUTH, OTP, PASSWORD_RECOVERY } from '@shared/constants';

import Routing from '../pages';
import { UserContext } from './contexts/user-context';

import './i18n/config';
import { useAxiosInterceptors, useScrollToTop, useStores } from '@shared/index';
import { observer } from 'mobx-react-lite';

const AuthPage = lazy(() => import('@pages/auth'));
const PasswordRecoveryPage = lazy(() => import('@pages/password-recovery'));
const OtpPage = lazy(() => import('@pages/otp'));

export const AppContent = observer(() => {
  const { isAuthenticated } = useContext(UserContext);
  const {
    MainStore: {
      agentUserStore: { agent, confirmToken },
    },
  } = useStores();
  useAxiosInterceptors();
  useScrollToTop();

  const { pathname } = useLocation();

  return (
    <Suspense fallback={<Spinner />}>
      {isAuthenticated ? (
        <Routing />
      ) : (
        <Routes>
          <Route path={AUTH} element={<AuthPage />} />
          <Route path={PASSWORD_RECOVERY} element={<PasswordRecoveryPage />} />
          {!!agent?.phone && confirmToken && (
            <Route path={OTP} element={<OtpPage />} />
          )}
          <Route
            path="*"
            element={<Navigate state={{ redirectTo: pathname }} to={AUTH} />}
          />
        </Routes>
      )}
    </Suspense>
  );
});
