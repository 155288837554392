import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Alert } from '@pulse-web-ui/alert';

import { ProductList } from '@entities/product-list';
import {
  ABOUT,
  NO_AGENT_CONTRACT,
  AnalyticEvent,
  useStores,
  PULSE_SUPPORT_EMAIL,
  TextLink,
  STANDARDS,
  addTestAttribute,
} from '@shared/index';
import { sendAnalyticEvent } from '@app/web-analytic';

import {
  AlertButton,
  AlertText,
  Container,
  MoreButton,
  RewardHint,
} from './choose-product.styles';

export const ChooseProduct = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    MainStore: {
      agentUserStore: { availableSign },
    },
  } = useStores();

  const goToNoContractsPage = () => {
    sendAnalyticEvent(AnalyticEvent.AGENT_NEWAGREEMENT);
    navigate(NO_AGENT_CONTRACT);
  };

  const goToStandartsPage = () => {
    navigate(STANDARDS);
  };

  const shouldConcludeContract = useMemo(
    () =>
      !!availableSign?.visibilityDocuments &&
      !!availableSign.isContractAvailable,
    [availableSign]
  );

  const isUnableToConcludeContract = useMemo(
    () =>
      availableSign?.visibilityDocuments === false &&
      availableSign.isContractAvailable === false,
    [availableSign]
  );

  const ConcludeContractAlert = () => (
    <Alert
      type="warning"
      description={
        <>
          <AlertText>{t('CHOOSE_PRODUCT:alerts.contract')}</AlertText>
          <AlertButton
            label={t('CHOOSE_PRODUCT:buttons.sign')}
            variant="text"
            onClick={goToNoContractsPage}
            {...addTestAttribute('alert.concludeContract.button')}
          />
        </>
      }
    />
  );

  const UnableToConcludeContractAlert = () => (
    <Alert
      type="warning"
      description={
        <AlertText>
          <Trans
            values={{ href: PULSE_SUPPORT_EMAIL }}
            components={{ a: <TextLink /> }}
          >
            {t('CHOOSE_PRODUCT:alerts.unableToConcludeContract')}
          </Trans>
        </AlertText>
      }
    />
  );

  const ReadStandardsAlert = () => (
    <Alert
      type="warning"
      description={
        <>
          <AlertText>{t('CHOOSE_PRODUCT:alerts.standards')}</AlertText>
          <AlertButton
            label={t('CHOOSE_PRODUCT:buttons.read')}
            variant="text"
            onClick={goToStandartsPage}
          />
        </>
      }
    />
  );

  return (
    <Container>
      {shouldConcludeContract && <ConcludeContractAlert />}
      {isUnableToConcludeContract && <UnableToConcludeContractAlert />}
      {availableSign?.visibilityStandard && <ReadStandardsAlert />}
      <div>
        <ProductList />
        <MoreButton
          label={t('CHOOSE_PRODUCT:buttons.moreAboutProducts')}
          variant="text"
          onClick={() => {
            sendAnalyticEvent(AnalyticEvent.AGENT_MOREDETAILS, {
              isagent: availableSign?.visibilityDocuments ? 'n' : 'y',
            });
            window.open(ABOUT, '_blank');
          }}
        />
        <RewardHint>{t('CHOOSE_PRODUCT:hints.rewardHint')}</RewardHint>
      </div>
    </Container>
  );
});
