export enum AgentDocumentType {
  CONSENT = 'CONSENT',
  DOCUMENT = 'DOCUMENT',
  ACT = 'ACT',
  STANDARD = 'STANDARD',
}

export enum Gender {
  MALE = '1',
  FEMALE = '2',
}

export enum AgentType {
  FL = 'FL',
  IP = 'IP',
  LE = 'LE',
}
