import { useState, useCallback, Suspense, lazy, memo } from 'react';
import { Modal } from '@pulse-web-ui/modal';

import {
  HintButton,
  StyledTitle,
  StyledContent,
  StyledCloseIcon,
  StyledDescription,
} from './button-with-modal.styles';

const CloseLight = lazy(
  () => import('@pulse-web-ui/icons/lib/lazy/close-light')
);

interface Props {
  label: string;
  title?: string;
  description?: string;
  submitLabel?: string;
}

export const ButtonWithModal = memo(
  ({ label, title, description, submitLabel }: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleCloseModal = useCallback(() => setIsOpen(false), []);
    const handleOpenModal = useCallback(() => setIsOpen(true), []);
    return (
      <>
        <HintButton label={label} variant="text" onClick={handleOpenModal} />
        <Modal
          closeIcon={false}
          showDialog={isOpen}
          submitLabel={submitLabel}
          closeHandler={handleCloseModal}
          submitHandler={handleCloseModal}
        >
          <StyledContent>
            {title && <StyledTitle>{title}</StyledTitle>}
            <StyledCloseIcon onClick={handleCloseModal}>
              <Suspense fallback={null}>
                <CloseLight width={24} />
              </Suspense>
            </StyledCloseIcon>
            {description && (
              <StyledDescription>{description}</StyledDescription>
            )}
          </StyledContent>
        </Modal>
      </>
    );
  }
);
