import { Button } from '@pulse-web-ui/button';
import { styled, typography } from '@pulse-web-ui/theme';

export const PageBackButton = styled(Button)`
  ${typography.regular16}
  margin-bottom: 16px;
  position: relative;
  z-index: 1;
  padding-left: 30px;
  box-sizing: border-box;

  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 0px;
    width: 18px;
    height: 2px;
    background: ${({ theme }) => theme.colors.text.interctive};
  }

  &:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 0px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    border-bottom: 2px solid ${({ theme }) => theme.colors.text.interctive};
    border-left: 2px solid ${({ theme }) => theme.colors.text.interctive};
  }
`;

export const SubmitButton = styled(Button)`
  width: 100%;
`;

export const IconButton = styled.div<{ isPrimary?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: ${({ theme }) => theme.common.radius};
  background: ${({ theme, isPrimary }) =>
    isPrimary ? theme.colors.surface.primary : theme.colors.surface.background};
  cursor: pointer;
  flex-shrink: 0;
`;

export const LinkButton = styled(Button)`
  font-size: 16px;
`;
