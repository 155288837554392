import { WidgetContainer } from '@pulse-web-ui/containers';
import { styled } from '@pulse-web-ui/theme';

export const Container = styled(WidgetContainer)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;

  // переопределение стилей @pulse-web-ui
  &:last-child {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const ActWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  height: 40px;
  cursor: pointer;
`;
