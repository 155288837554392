import { styled, typography } from '@pulse-web-ui/theme';

export const Card = styled.li`
  ${typography.medium16}
  list-style: none;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[30]};
  border-radius: ${({ theme }) => theme.common.radius};
  cursor: pointer;
`;

export const Info = styled.span`
  display: flex;
  flex-direction: column;
`;

export const Subtitle = styled.span`
  ${typography.regular12}
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const Icon = styled.img`
  width: 20px;
  height: 32px;
  object-fit: contain;
`;
