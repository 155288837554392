import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  Act,
  Burger,
  Close,
  Coins,
  Person,
  PhoneTransparent,
  Pulse,
  List,
  Attach,
  Back,
  Statements,
} from '@pulse-web-ui/icons';
import {
  ABOUT,
  AnalyticEvent,
  CONTACTS,
  IconButton,
  PROFILE,
  ProductCode,
  SRG_LINK,
  STANDARDS,
  addTestAttribute,
  useStores,
} from '@shared/index';
import { useTheme } from '@pulse-web-ui/theme';
import { UserContext } from '@contexts/user-context';
import {
  HeaderButtons,
  HeaderContainer,
  HeaderNav,
  IconContainer,
  LogoLink,
  LogoutButton,
  MortgageApplications,
  NavItemLink,
  NavList,
  PulseLogo,
  RgsLogo,
  Separator,
} from './header.styles';
import pulseLogo from '../../assets/images/pulse-logo.svg';
import rgsLogo from '../../assets/images/rgs-logo.svg';
import { sendAnalyticEvent } from '../web-analytic';
import { observer } from 'mobx-react-lite';

const NEWS_AND_EDUCATION_LINK =
  'https://dashing-consonant-efe.notion.site/d31676b16c4e44c69e1bca95e775560c';

export const Header = observer(() => {
  const { isAuthenticated } = useContext(UserContext);
  const {
    MainStore: {
      productsStore: { products },
    },
  } = useStores();
  const theme: any = useTheme();
  const { t } = useTranslation();
  const [isNavDisplayed, setIsNavDisplayed] = useState(false);

  const showMortgageApplications = useMemo(
    () =>
      !!products?.find(
        ({ productCode }) => productCode === ProductCode.MORTGAGE
      ),
    [products]
  );

  const toggleNav = () => {
    if (isNavDisplayed) {
      document.body.style.overflow = 'initial';
    } else {
      document.body.style.overflow = 'hidden';
    }
    setIsNavDisplayed(!isNavDisplayed);
  };

  const closeMenu = () => {
    document.body.style.overflow = 'initial';
    setIsNavDisplayed(false);
  };

  const handleMenuItemClick = (event?: AnalyticEvent) => () => {
    event && sendAnalyticEvent(event);
    closeMenu();
  };

  return (
    <>
      <HeaderContainer>
        <LogoLink onClick={closeMenu} to="/">
          <PulseLogo src={pulseLogo} alt="Logo" />
          <Separator />
          <RgsLogo src={rgsLogo} alt="Logo" />
        </LogoLink>
        {isAuthenticated && (
          <HeaderButtons>
            <Link
              to={PROFILE}
              onClick={closeMenu}
              {...addTestAttribute('header-profile')}
            >
              <IconButton>
                <Person width={18} color={theme.colors.icon.primary} />
              </IconButton>
            </Link>
            <IconButton onClick={toggleNav} {...addTestAttribute('header-nav')}>
              {isNavDisplayed ? (
                <Close width={20} color={theme.colors.icon.primary} />
              ) : (
                <Burger width={18} color={theme.colors.icon.primary} />
              )}
            </IconButton>
          </HeaderButtons>
        )}
      </HeaderContainer>
      {isNavDisplayed && (
        <HeaderNav>
          <NavList>
            <li>
              <NavItemLink
                to="/sales"
                onClick={handleMenuItemClick(AnalyticEvent.AGENT_SALES_M)}
                {...addTestAttribute('navigation-sales')}
              >
                <IconButton>
                  <Coins width={24} color={theme.colors.icon.primary} />
                </IconButton>
                <span>{t('COMMON:navigation.sales')}</span>
              </NavItemLink>
            </li>
            <li>
              <NavItemLink
                to="/acts"
                onClick={handleMenuItemClick(AnalyticEvent.AGENT_STATEMENT_M)}
                {...addTestAttribute('navigation-acts')}
              >
                <IconButton>
                  <Act
                    width={24}
                    color={theme.colors.icon.primary}
                    viewBox="0 0 24 24"
                  />
                </IconButton>
                <span>{t('COMMON:navigation.acts')}</span>
              </NavItemLink>
            </li>
            <li>
              <NavItemLink
                to={ABOUT}
                onClick={handleMenuItemClick(
                  AnalyticEvent.AGENT_ABOUTPRODUCTS_M
                )}
                {...addTestAttribute('navigation-about')}
              >
                <IconButton>
                  <Pulse width={24} color={theme.colors.icon.primary} />
                </IconButton>
                <span>{t('COMMON:navigation.about')}</span>
              </NavItemLink>
            </li>
            <li>
              <NavItemLink
                to={CONTACTS}
                onClick={handleMenuItemClick(AnalyticEvent.AGENT_CONTACTS_M)}
                {...addTestAttribute('navigation-contacts')}
              >
                <IconButton>
                  <PhoneTransparent
                    width={24}
                    color={theme.colors.icon.primary}
                  />
                </IconButton>
                <span>{t('COMMON:navigation.contacts')}</span>
              </NavItemLink>
            </li>
            <li>
              <NavItemLink
                to={NEWS_AND_EDUCATION_LINK}
                onClick={handleMenuItemClick(AnalyticEvent.AGENT_CONTACTS_M)}
                target="_blank"
              >
                <IconButton>
                  <List
                    width={24}
                    viewBox="0 0 16 24"
                    color={theme.colors.icon.primary}
                  />
                </IconButton>
                <span>{t('COMMON:navigation.newsAndEducation')}</span>
              </NavItemLink>
            </li>
            <li>
              <NavItemLink
                to={STANDARDS}
                onClick={handleMenuItemClick()}
                {...addTestAttribute('navigation-standards')}
              >
                <IconButton>
                  <Statements
                    width={24}
                    viewBox="0 0 24 24"
                    color={theme.colors.icon.primary}
                  />
                </IconButton>
                <span>{t('COMMON:navigation.agentStandards')}</span>
              </NavItemLink>
            </li>
            {showMortgageApplications && (
              <li>
                <NavItemLink
                  to={SRG_LINK}
                  onClick={handleMenuItemClick()}
                  target="_blank"
                >
                  <IconButton>
                    <Attach
                      width={24}
                      viewBox="0 0 24 24"
                      color={theme.colors.icon.primary}
                    />
                  </IconButton>
                  <MortgageApplications>
                    {t('COMMON:navigation.mortgageApplications')}
                    <IconContainer>
                      <Back width={16} color={theme.colors.icon.primary} />
                    </IconContainer>
                  </MortgageApplications>
                </NavItemLink>
              </li>
            )}
          </NavList>
          <LogoutButton
            onLogout={handleMenuItemClick(AnalyticEvent.AGENT_EXIT_M)}
          />
        </HeaderNav>
      )}
    </>
  );
});
