import { lazy, useCallback, useContext, useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useErrorBoundary } from 'react-error-boundary';
import { Spinner } from '@pulse-web-ui/spinner';
import {
  MAIN,
  ACTS,
  AGENT_CONTRACT,
  NO_AGENT_CONTRACT,
  CONFIRM_CONTRACT,
  CREATE_SUBSCRIPTION,
  PROFILE,
  ERROR,
  ABOUT,
  CONTACTS,
  STATUSES_INFO,
  SALES,
  PAYMENT_INFO,
  SIGN_ACT,
  AgentStatus,
  BoundaryErrorType,
  UNABLE_TO_CONCLUDE_CONTRACT,
  ACCESS_SRG,
  AGENT_TYPE,
  STANDARDS,
  SMS_SENT,
} from '@shared/constants';
import { observer } from 'mobx-react-lite';
import { useStores } from '@src/shared';
import { sendUserIdToAnalytics } from '@src/app/web-analytic';
import { UserContext } from '@src/app/contexts/user-context';
import { useGetProfile } from '@entities/agent-contract';

const ActsPage = lazy(() => import('./acts'));
const ActPage = lazy(() => import('./act'));
const MainPage = lazy(() => import('./main'));
const AgentContractPage = lazy(() => import('./agent-contract'));
const NoAgentContractPage = lazy(() => import('./no-agent-contract'));
const ConfirmContractPage = lazy(() => import('./confirm-contract'));
const CreateSubscriptionPage = lazy(() => import('./create-subscription'));
const ProfilePage = lazy(() => import('./profile'));
const ProductsPage = lazy(() => import('./products'));
const ErrorPage = lazy(() => import('./error'));
const ContactsPage = lazy(() => import('./contacts'));
const StatusesInfoPage = lazy(() => import('./statuses-info'));
const SalesPage = lazy(() => import('./sales'));
const SmsIsSentPage = lazy(() => import('./sms-is-sent'));
const ActsPaymentInfoPage = lazy(() => import('./acts-payment-info'));
const SignActPage = lazy(() => import('./sign-act'));
const UnableToConcludeContractPage = lazy(
  () => import('./unable-to-conclude-contract')
);
const AcessSRGPage = lazy(() => import('./access-srg'));
const AgentType = lazy(() => import('./agent-type'));
const StandardsPage = lazy(() => import('./standards'));

const Routing = observer(() => {
  const { showBoundary } = useErrorBoundary();
  const {
    MainStore: {
      agentContractStore: { profile, updateProfile, setAgentDocuments },
      agentUserStore: { confirmToken, setAgentBill, setAvailableSign },
      productsStore: { setProducts },
    },
  } = useStores();
  const { pathname } = useLocation();
  const { getUserName } = useContext(UserContext);
  const { res, isFetching, refetch: getAgentProfile, error } = useGetProfile();

  const initAgentProfile = useCallback(() => {
    getAgentProfile().then(({ data }) => {
      if (data?.data) {
        const { profile, infoBill, availableSign, agentDocuments, products } =
          data.data;

        updateProfile(profile);
        setAgentBill(infoBill);
        setAvailableSign(availableSign);
        setProducts(products);

        setAgentDocuments(agentDocuments || []);
      }
    });
  }, []);

  useEffect(() => {
    if (
      !res ||
      [
        MAIN,
        PROFILE,
        AGENT_CONTRACT,
        NO_AGENT_CONTRACT,
        UNABLE_TO_CONCLUDE_CONTRACT,
      ].includes(pathname)
    ) {
      initAgentProfile();
    }
  }, [pathname]);

  useEffect(() => {
    if (error) {
      showBoundary(BoundaryErrorType.SomethingWentWrong);
    }
  }, [error]);

  useEffect(() => {
    if (!!profile?.phone || !!getUserName()) {
      sendUserIdToAnalytics(profile?.phone || (getUserName() as string));
    }
  }, [profile?.phone || getUserName()]);

  const isConfirmedAgent = profile?.status === AgentStatus.CONFIRM;

  if (isFetching || !res) {
    return <Spinner />;
  }

  return (
    <Routes>
      <Route path={MAIN} element={<MainPage />} />
      <Route path={ACTS} element={<ActsPage />} />
      <Route path={`${ACTS}/:actId`} element={<ActPage />} />
      {!isConfirmedAgent && (
        <>
          <Route path={AGENT_CONTRACT} element={<AgentContractPage />} />
          <Route path={NO_AGENT_CONTRACT} element={<NoAgentContractPage />} />
          <Route path={AGENT_TYPE} element={<AgentType />} />
          <Route
            path={UNABLE_TO_CONCLUDE_CONTRACT}
            element={<UnableToConcludeContractPage />}
          />
        </>
      )}
      {isConfirmedAgent && (
        <Route
          path={CREATE_SUBSCRIPTION}
          element={<CreateSubscriptionPage />}
        />
      )}
      <Route path={STANDARDS} element={<StandardsPage />} />
      <Route path={ACCESS_SRG} element={<AcessSRGPage />} />
      <Route path={PROFILE} element={<ProfilePage />} />
      <Route path={ABOUT} element={<ProductsPage />} />
      <Route path={CONTACTS} element={<ContactsPage />} />
      <Route path={STATUSES_INFO} element={<StatusesInfoPage />} />
      <Route path={SALES} element={<SalesPage />} />
      <Route path={SMS_SENT} element={<SmsIsSentPage />} />
      <Route path={PAYMENT_INFO} element={<ActsPaymentInfoPage />} />
      {!!confirmToken && <Route path={SIGN_ACT} element={<SignActPage />} />}
      {!!profile?.phone && !!confirmToken && (
        <Route path={CONFIRM_CONTRACT} element={<ConfirmContractPage />} />
      )}
      <Route path={ERROR} element={<ErrorPage />} />
      <Route path="*" element={<Navigate to={MAIN} />} />
    </Routes>
  );
});

export default Routing;
