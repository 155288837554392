import { Button } from '@pulse-web-ui/button';
import { styled, typography } from '@pulse-web-ui/theme';

export const HintButton = styled(Button)`
  ${typography.regular14};
  line-height: 20px;
  margin-bottom: 16px;
`;

export const StyledCloseIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 16px;
  top: 24px;
  cursor: pointer;
  padding: 4px;
  background: ${({ theme }) => theme.colors.surface.background};
  border-radius: 16px;
`;

export const StyledTitle = styled.h3`
  width: 100%;
  text-align: left;
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: 8px;
`;

export const StyledDescription = styled.div`
  width: 100%;
  margin-top: 4px;
`;

export const StyledContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;
