import { useRequest, RequestMethods, AgentEndpoints } from '@src/shared';
import { CommonResponse } from '@src/shared/types';

import type { GetProfileResponse } from './get-profile.types';

export const useGetProfile = () => {
  const { res, isLoading, refetch, error, status, isFetching } = useRequest<
    never,
    GetProfileResponse,
    CommonResponse
  >({
    params: {
      key: 'getProfile',
      method: RequestMethods.GET,
      url: `${AgentEndpoints.AGENTS}/profile`,
      fetchManual: true,
    },
  });

  return {
    res,
    error,
    isLoading,
    refetch,
    status,
    isFetching,
  };
};
